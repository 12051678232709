@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&display=swap');

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


body {
    font-family: 'Josefin Sans', sans-serif;
}
